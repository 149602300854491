import {
    AutocompleteInput,
    ReferenceInput,
    required,
    SimpleForm,
    Create,
    Toolbar, useNotify, useTranslate, SaveButton, useDataProvider
} from "react-admin";
import React, {useEffect, useState} from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {Button, CircularProgress, LinearProgress, Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RecursiveTreeView from "../../components/RecursiveTreeView";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import 'dayjs/locale/hu';
import dayjs from "dayjs";
import { useLocaleState } from 'react-admin';
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {ThemeProvider} from "@mui/material/styles";


export const ReportCreate = () => {
    const dataProvider = useDataProvider()
    const translate = useTranslate()
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined})
    const [report, setReport] = useState(undefined)
    const [selected, setSelected] = React.useState([])
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const notify = useNotify()
    const [queryParams, setQueryParams] = useState({
        rangeStart: dayjs().subtract(1, 'day'),
        rangeEnd: dayjs(),
    })
    const locale = useLocaleState();



    useEffect(() => {
        setReport(null)
    }, [selected, queryParams])

    const ReportToolbar = () =>{
        const [isLoading, setLoading] = useState(false)
        const activePosSetId = parseInt(localStorage.getItem('activePosSetId'))

        const sortTreeData = (data) => {
            return data.sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                if (item.children) {
                    item.children = sortTreeData(item.children);
                }
                return item;
            });
        };

        useEffect(() => {
            if (!data.loading && !data.loaded) {
                setData({...data, loading: true});
                dataProvider.get('report')
                    .then(value => {
                        const sortedData = sortTreeData(value.data);
                        setData({loading: false, loaded: true, data: sortedData, error: undefined});
                        let selectedId = [activePosSetId]
                        const recId = e => {
                            if( e.children && selectedId.includes(e.id) ){
                                e.children?.forEach(el => selectedId.push(el.id))
                            }
                            e.children && e.children?.forEach(recId)
                        }
                        Array.isArray(value.data) ? value.data.forEach(x => recId(x)) : recId(value.data)
                        setSelected(selectedId)
                    })
                    .catch(reason => {
                        setData({loading: false, loaded: true, data: undefined, error: reason});
                    })
            }
        }, [data.loading, data.loaded]);

        return (
            <Toolbar>
                <SaveButton
                    disabled={selected.length === 0 || queryParams.rangeStart > queryParams.rangeEnd}
                    label="report.add"
                    icon={<AssessmentIcon />}
                    mutationOptions={{
                        onSuccess: (data) => {
                            notify('report.created');
                            const contentType = data.response.contentType
                            const base64Data = data.response.file
                            const fileName = data.response.fileName
                            const linkSource = `data:${contentType};base64,${base64Data}`;
                            setReport({file:linkSource, fileName: fileName})
                            setLoading(false)
                        }}
                    }
                    type="button"
                    variant="text"
                />
                { report ?
                    <>
                        <a download={report.fileName} href={report.file} style={{textDecoration: "none", marginLeft: "16px"}}>
                        <Button variant={"contained"} style={{color: "white"}} color={"primary"} >
                            <FileDownloadIcon />   {translate('report.download')}
                        </Button>
                        </a>
                        <Typography style={{ marginLeft: "16px"}} variant={"h6"}>{report.fileName}</Typography>
                    </>
                    :
                   null
                }
                {isLoading ?
                    <CircularProgress />
                    :
                    null
                }
            </Toolbar>
        )
    }

    const transform = formData => ({
        type: formData.type,
        selectedPosSets: selected,
        start: queryParams.rangeStart,
        end: queryParams.rangeEnd
    });

    return (

        <Create transform={transform}>
            <SimpleForm toolbar={<ReportToolbar />} >
                <ReferenceInput source="type"
                                reference="report/type">
                    <AutocompleteInput optionText={"name"} fullWidth={true} label={"report.type"}
                                       validate={required()} onChange={() => setReport(null)}/>
                </ReferenceInput>
                {(data.loading || !data.loaded)
                    ?
                        <LinearProgress/>
                    :
                        <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                            {Array.isArray(data.data)
                                ?
                                    data.data.map((tree, i) =>{
                                    return(<RecursiveTreeView key={i} data={tree} selected={selected} setSelected={setSelected} disabledList={[]} />)
                                    })
                                :
                                    <RecursiveTreeView data={data.data} selected={selected} setSelected={setSelected} disabledList={[]} />
                            }
                        </div>
                }

                <div style={{display: "flex", flexDirection: "row"}}>
                    <ThemeProvider theme={muiDatagridTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                        <DateTimePicker
                            label={translate("report.date.start")}
                            value={queryParams.rangeStart || null}
                            maxDate={queryParams.rangeEnd}
                            onChange={(newValue) => {
                                setQueryParams({...queryParams, rangeStart: newValue});
                            }}
                        />

                        <DateTimePicker
                            label={translate("report.date.end")}
                            value={queryParams.rangeEnd || null}
                            minDate={queryParams.rangeStart}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                                setQueryParams({...queryParams, rangeEnd: newValue});
                            }}
                        />
                    </LocalizationProvider>
                    </ThemeProvider>
                </div>
            </SimpleForm>
        </Create>
    )
}