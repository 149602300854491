import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogContent, DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import dataProvider from "../../../components/dataProvider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNotify, useTranslate } from "react-admin";
import bigDecimal from "js-big-decimal";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


const CustomTable = ({ setValue, record, detailedData, setAllItemsFilled }) => {
    const [products, setProducts] = useState([])
    const [items, setItems] = useState([])
    const posSetId = localStorage.getItem("operationsPosSetId")
    const [sendData, setSendData] = useState([])
    const [vatGroup, setVatGroup] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [siUnits, setSiUnits] = useState([])
    const sumNett = bigDecimal.round(items.reduce((a, b) => a + Number(b.netSum), 0), 2)
    const sumBrut = bigDecimal.round(items.reduce((a, b) => a + Number(b.grossSum), 0), 2)
    const translate = useTranslate()
    const notify = useNotify()
    let importedWarehouseOperations = localStorage.getItem("importedWarehouseOperations") || null

    useEffect(() => {
        dataProvider.get(`warehouse-operation/product`, { posSetId: posSetId })
            .then(value => {
                setProducts(value.data)
            })
            .catch(reason => {
                setProducts([])
            })
        dataProvider.get(`warehouse-operation/units`)
            .then(value => {
                setSiUnits(value.data)
            })
            .catch(reason => {
                setSiUnits([])
            })
        dataProvider.get(`product/vatGroup`)
            .then(value => {
                setVatGroup(value.data.content)
            })
            .catch(reason => {
                setVatGroup([])
            })
    }, [])

    useEffect(() => {
        setValue("items", sendData)
    }, [sendData])

    useEffect(() => {
        const allItemsFilled = items.every(item => item.name && item.unit && item.quantity != 0 && item.grossUnitPrice && item.netUnitPrice)
        setAllItemsFilled(allItemsFilled)
        if (items.length === 0) {
            localStorage.removeItem("importedWarehouseOperations")
        }
    }, [items])

    useEffect(() => {
        const updatedRows = [...items];
        updatedRows.forEach((row, index) => {
            let quantity = new bigDecimal(row.quantity)
            let vat = isNaN(row.vat) ? new bigDecimal(100) : new bigDecimal(parseInt(row.vat) + 100)
            let vatDivided = new bigDecimal(bigDecimal.divide(vat.value, 100, 10))
            let grossUnitPrice = new bigDecimal(row.grossUnitPrice)
            let globalDiscount = quantity.multiply(grossUnitPrice.divide(vatDivided)).divide(new bigDecimal(100)).multiply(new bigDecimal(detailedData.discount))
            let discount = globalDiscount.round(0)
            let netSum = quantity.multiply(grossUnitPrice.divide(vatDivided)).subtract(discount)
            let grossSum = netSum.multiply(vatDivided)
            updatedRows[index].netSum = netSum.round(2).value
            updatedRows[index].grossSum = grossSum.round(2).value
            updatedRows[index].discount = discount.round(0).value
        })
        setSendData(prevSendData => {
            const tempArray = [...prevSendData]
            updatedRows.forEach((row, index) => {
                tempArray[index].netDiscountAmount = row.discount
                tempArray[index].netSum = row.netSum
                tempArray[index].grossSum = row.grossSum
            })
            return tempArray
        })
        setItems(updatedRows)

    }, [detailedData.discount])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const promises = items.map(async (item, index) => {
                    try {
                        const itemWhitStock = await stockChange(item)
                        return await defaultPrice(itemWhitStock, index)
                    } catch (error) {
                        console.error(error);
                        return item;
                    }
                });
                const updatedItems = await Promise.all(promises);

                setItems(updatedItems)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();


    }, [trigger, detailedData.dateTime])

    useEffect(() => {
        if (record) {
            const fetchProductData = async (item) => {
                const product = products.find((product) => {
                    return product.id === item.warehouseProductId;
                });

                if (product) {
                    let vat = vatGroup.find((vat) => vat.id === product.vatId);
                    vat = vat.name.split('%')[0];
                    isNaN(vat) ? vat = 0 : vat = parseInt(vat)
                    const units = product.units;
                    let unit = null
                    if (item.unitId !== null) {
                        const unitData = await dataProvider.get(`warehouse-operation/unit`, { unitId: item.unitId });
                        for (const siUnit of unitData.data) {
                            siUnit.isUnitId = true;
                            units.push(siUnit);
                        }
                        unit = unitData.data.find((u) => u.id === item.unitId);
                    } else {
                        unit = product.units.find((u) => u.id === item.warehouseProductUnitId);
                        if (unit.unitId !== null && unit.unitId !== undefined) {
                            const unitData = await dataProvider.get(`warehouse-operation/unit`, { unitId: unit.unitId });
                            for (const custom of unitData.data) {
                                if (!units.find((u) => u.id === custom.id)) {
                                    custom.isUnitId = true;
                                    units.push(custom);
                                }

                            }
                        } else {
                            const findUnit = units.find((u) => u.unitId !== null && u.unitId !== undefined);
                            const unitData = await dataProvider.get(`warehouse-operation/unit`, { unitId: findUnit.unitId });
                            for (const custom of unitData.data) {
                                if (!units.find((u) => u.id === custom.id)) {
                                    units.push(custom);
                                }
                            }
                        }
                    }
                    return {
                        id: item.id,
                        name: product,
                        units: units,
                        quantity: item.quantity,
                        unit: unit,
                        vat: vat,
                        netUnitPrice: bigDecimal.round((bigDecimal.divide(item.unitGrossPrice, bigDecimal.divide(100 + vat, 100, 10), 10)), 2),
                        grossUnitPrice: item.unitGrossPrice.toFixed(2),
                        discount: item.netDiscountAmount,
                        netSum: bigDecimal.round((item.quantity * (bigDecimal.divide(item.unitGrossPrice, bigDecimal.divide(100 + vat, 100, 10), 10)) - item.netDiscountAmount), 2),
                        grossSum: bigDecimal.round((item.quantity * (bigDecimal.divide(item.unitGrossPrice, bigDecimal.divide(100 + vat, 100, 10), 10)) - item.netDiscountAmount) * (bigDecimal.divide(100 + vat, 100, 10)), 2)
                    };
                }

                return null;
            };

            const fetchDataForItems = async () => {
                const updatedRows = [];
                const updatedSendData = [];

                for (const item of record.items) {
                    const itemData = await fetchProductData(item);
                    if (itemData) {
                        updatedRows.push(itemData);
                        updatedSendData.push({
                            id: item.id,
                            warehouseProductUnitId: item.warehouseProductUnitId,
                            unitId: item.unitId,
                            quantity: item.quantity,
                            unitGrossPrice: item.unitGrossPrice,
                            netDiscountAmount: item.netDiscountAmount === "" ? 0 : item.netDiscountAmount,
                            netSum: itemData.netSum,
                            grossSum: itemData.grossSum,
                            vat: itemData.vat,
                            netUnitPrice: itemData.netUnitPrice,
                            warehouseProductId: item.warehouseProductId,
                        });
                    }
                }

                if (record.type === 'MOVE' || record.type === 'WASTE' || record.type === 'TO_SUPPLIER') {
                    const sourceStock = await dataProvider.get("warehouse-operation/stock-for-operation", {
                        posSetId: posSetId,
                        warehouseOperationId: record.id,
                        warehouseId: record.source,
                        dateTime: record.dateTime
                    })
                    updatedRows.forEach(item => {
                        const whProduct = sourceStock.data.find(product => product.warehouseProductId === item.name.id);
                        if (whProduct) {
                            item.sourceStock = whProduct.stock;
                        }
                    });
                }

                if (record.type === 'MOVE' || record.type === 'FROM_SUPPLIER') {
                    const targetStock = await dataProvider.get("warehouse-operation/stock-for-operation", {
                        posSetId: posSetId,
                        warehouseOperationId: record.id,
                        warehouseId: record.target,
                        dateTime: record.dateTime
                    })
                    updatedRows.forEach(item => {
                        const whProduct = targetStock.data.find(product => product.warehouseProductId === item.name.id);
                        if (whProduct) {
                            item.targetStock = whProduct.stock;
                        }
                    });

                }
                setItems(updatedRows)
                setSendData(updatedSendData)
            };

            fetchDataForItems();
        }
    }, [products]);

    useEffect(() => {
        if (detailedData.source && detailedData.target && detailedData.type && items.length > 0) {
            setOpenDialog(true)
        }
    }, [detailedData.source, detailedData.target, detailedData.type]);


    useEffect(() => {
        if (importedWarehouseOperations) {
            const updatedRows = [];
            const updatedSendData = [];
            JSON.parse(importedWarehouseOperations).forEach(item => {
                const product = products.find(product => product.name === item.name)
                if (product !== undefined) {
                    let unitId = `unit-${product.units.find(unit => unit.unitId !== null)?.unitId}`
                    let normalUnits = siUnits.filter(item => item.type === siUnits.find(item => item.id === unitId).type).map(item => {
                        return {
                            id: item.id.replace('unit-', ''),
                            name: item.unitName,
                            isUnitId: true
                        }
                    })
                    let units = product.units.concat(normalUnits)
                    let vat
                    if (typeof item.vat === 'string') {
                        vat = parseInt(item.vat.replace('%', ''))
                    } else {
                        vat = String((item.vat * 100).toFixed(0))
                    }
                    let vatAmount = new bigDecimal(1 + vat / 100)
                    let quantity = new bigDecimal(item.quantity)
                    let discount = item.discount === undefined ? new bigDecimal(0) : new bigDecimal(item.discount)
                    let netUnitPrice = new bigDecimal(item.netUnitPrice).round(2, bigDecimal.RoundingModes.HALF_UP).value
                    let grossUnitPrice = new bigDecimal(item.netUnitPrice).multiply(vatAmount).round(2, bigDecimal.RoundingModes.HALF_UP).value
                    let netSum = new bigDecimal(item.netUnitPrice).multiply(quantity).subtract(discount).round(2, bigDecimal.RoundingModes.HALF_UP).value
                    let grossSum = new bigDecimal(item.netUnitPrice).multiply(vatAmount).multiply(quantity).subtract(discount).round(2, bigDecimal.RoundingModes.HALF_UP).value

                    let row = {
                        id: product.id,
                        name: product,
                        quantity: quantity.value,
                        units: units,
                        unit: units.find(unit => unit.name == item.unit),
                        vat: vat,
                        netUnitPrice: netUnitPrice,
                        grossUnitPrice: grossUnitPrice,
                        discount: discount.value,
                        netSum: netSum,
                        grossSum: grossSum
                    }
                    let sendData = {
                        warehouseProductId: product?.id,
                        warehouseProductUnitId: siUnits.map(item => item.id.replace('unit-', '')).includes(product?.defaultWarehouseUnitId) ? null : product?.defaultWarehouseUnitId,
                        unitId: siUnits.map(item => item.id.replace('unit-', '')).includes(product?.defaultWarehouseUnitId) ? product?.defaultWarehouseUnitId : null,
                        quantity: quantity.value,
                        unitGrossPrice: grossUnitPrice,
                        netDiscountAmount: discount.value,
                        netSum: netSum,
                        grossSum: grossSum,
                        vat: vat,
                        netUnitPrice: netUnitPrice,
                    }
                    updatedRows.push(row)
                    updatedSendData.push(sendData)
                }
            });
            setItems(updatedRows.filter(item => item !== undefined))
            setSendData(updatedSendData.filter(item => item !== undefined))
        }
    }, [importedWarehouseOperations, products])

    const stockChange = async (item) => {
        const unitId = item.unit.isUnitId ? `unit-${item.unit.id}` : item.unit.id;
        let sourceStock = 0;
        let targetStock = 0;
        if ((detailedData.type.type === 'MOVE' || detailedData.type.type === 'WASTE' || detailedData.type.type === 'TO_SUPPLIER') && detailedData.source && detailedData.source.id) {
            const sourceRes = await dataProvider.get(`warehouse-operation/stock`, {
                posSetId: posSetId,
                warehouseId: detailedData.source.id,
                warehouseProductId: item.name.id,
                unitId: unitId,
                dateTime: detailedData.dateTime.toISOString()
            });
            sourceStock = sourceRes.data;
        }
        if ((detailedData.type.type === 'MOVE' || detailedData.type.type === 'FROM_SUPPLIER') && detailedData.target && detailedData.dateTime) {
            const targetRes = await dataProvider.get(`warehouse-operation/stock`, {
                posSetId: posSetId,
                warehouseId: detailedData.target.id,
                warehouseProductId: item.name.id,
                unitId: unitId,
                dateTime: detailedData.dateTime.toISOString()
            });
            targetStock = targetRes.data;
        }

        item.sourceStock = sourceStock;
        item.targetStock = targetStock;
        return item;
    }

    const defaultPrice = async (item, index) => {
        const unitId = item.unit.isUnitId ? `unit-${item.unit.id}` : item.unit.id;
        let supplierNetPrice = 0;
        if (detailedData.type.type === 'FROM_SUPPLIER' && detailedData.source) {
            const inNetPrice = await dataProvider.get(`warehouse-operation/supplier-pricelist-item`, {
                supplierId: detailedData.source.id,
                type: 'IN',
                warehouseProductId: item.name.id,
                unitId: unitId
            })
            supplierNetPrice = inNetPrice.data
        } else if (detailedData.type.type === 'TO_SUPPLIER' && detailedData.target) {
            const outNetPrice = await dataProvider.get(`warehouse-operation/supplier-pricelist-item`, {
                supplierId: detailedData.target.id,
                type: 'OUT',
                warehouseProductId: item.name.id,
                unitId: unitId
            })
            supplierNetPrice = outNetPrice.data
        } else if ((detailedData.type.type === 'MOVE' || detailedData.type.type === 'WASTE') && detailedData.source) {
            const avgNetPrice = await dataProvider.get(`warehouse-operation/avg-net-price`, {
                posSetId: posSetId,
                warehouseId: detailedData.source.id,
                warehouseProductId: item.name.id,
                unitId: unitId,
                warehouseOperationType: detailedData.type.type,
            })
            supplierNetPrice = avgNetPrice.data
        } else {
            supplierNetPrice = 0
        }
        const vat = isNaN(item.vat) ? new bigDecimal(100) : new bigDecimal(parseInt(item.vat) + 100)
        const vatDivided = new bigDecimal(bigDecimal.divide(vat.value, 100, 10))
        const netPrice = new bigDecimal(supplierNetPrice).round(2, bigDecimal.RoundingModes.HALF_UP)
        const grossPrice = netPrice.multiply(vatDivided).round(2)
        const quantity = new bigDecimal(item.quantity)
        const discount = quantity.multiply(grossPrice.divide(vatDivided)).divide(new bigDecimal(100)).multiply(new bigDecimal(detailedData.discount)).round(0)
        const netSum = quantity.multiply(netPrice).subtract(discount).round(2)
        const grossSum = netSum.multiply(vatDivided).round(2)

        item.netSum = netSum.value
        item.grossSum = grossSum.value
        item.netUnitPrice = netPrice.value
        item.grossUnitPrice = grossPrice.value
        item.discount = discount.value

        setSendData(prevSendData => {
            const tempArray = [...prevSendData]
            tempArray[index].unitGrossPrice = grossPrice.value
            tempArray[index].netDiscountAmount = discount.value
            tempArray[index].netSum = netSum.value
            tempArray[index].grossSum = grossSum.value
            tempArray[index].netUnitPrice = netPrice.value
            return tempArray
        })

        return item
    }
    const handleInputChange = (e, rowIndex, field) => {
        const updatedRows = [...items];
        const value = e.target.value.replace(/[^0-9.,]/g, '').replace(/,/g, '.');
        value === "" ? updatedRows[rowIndex][field] = value : updatedRows[rowIndex][field] = new bigDecimal(value).value;
        let quantity = new bigDecimal(updatedRows[rowIndex].quantity)
        let vat = isNaN(updatedRows[rowIndex].vat) ? new bigDecimal(100) : new bigDecimal(parseInt(updatedRows[rowIndex].vat) + 100)
        let vatDivided = new bigDecimal(bigDecimal.divide(vat.value, 100, 10))
        let netUnitPrice = new bigDecimal(updatedRows[rowIndex].netUnitPrice)
        let grossUnitPrice = new bigDecimal(updatedRows[rowIndex].grossUnitPrice)
        let globalDiscount = field === "grossUnitPrice" ? quantity.multiply(grossUnitPrice.divide(vatDivided)).divide(new bigDecimal(100)).multiply(new bigDecimal(detailedData.discount)) : quantity.multiply(netUnitPrice.multiply(vatDivided).round(2).divide(vatDivided)).divide(new bigDecimal(100)).multiply(new bigDecimal(detailedData.discount))
        let discount = field === "discount" ? new bigDecimal(updatedRows[rowIndex].discount) : globalDiscount.round(0)
        let netSum = null
        let grossSum = null
        switch (field) {
            case "quantity":
                netSum = quantity.multiply(grossUnitPrice.divide(vatDivided)).subtract(discount)
                grossSum = netSum.multiply(vatDivided)
                updatedRows[rowIndex].netSum = netSum.round(2).value
                updatedRows[rowIndex].grossSum = grossSum.round(2).value
                updatedRows[rowIndex].discount = discount.round(0).value
                break;
            case "netUnitPrice":
                grossUnitPrice = netUnitPrice.multiply(vatDivided).round(2)
                netSum = quantity.multiply(grossUnitPrice.divide(vatDivided)).subtract(discount)
                grossSum = netSum.multiply(vatDivided)
                updatedRows[rowIndex].grossUnitPrice = grossUnitPrice.round(2).value
                updatedRows[rowIndex].netSum = netSum.round(2).value
                updatedRows[rowIndex].grossSum = grossSum.round(2).value
                updatedRows[rowIndex].discount = discount.round(0).value
                break;
            case "grossUnitPrice":
                netUnitPrice = grossUnitPrice.divide(vatDivided)
                netSum = quantity.multiply(netUnitPrice).subtract(discount)
                grossSum = netSum.multiply(vatDivided)
                updatedRows[rowIndex].netUnitPrice = netUnitPrice.round(2).value
                updatedRows[rowIndex].netSum = netSum.round(2).value
                updatedRows[rowIndex].grossSum = grossSum.round(2).value
                updatedRows[rowIndex].discount = discount.round(0).value
                break;
            case "discount":
                netSum = quantity.multiply(grossUnitPrice.divide(vatDivided)).subtract(discount)
                grossSum = netSum.multiply(vatDivided)
                updatedRows[rowIndex].netSum = netSum.round(2).value
                updatedRows[rowIndex].grossSum = grossSum.round(2).value
                break;
            default:
                break;

        }
        const hasNegativeResult = updatedRows.some(row => {
            const netSum = row.quantity * row.netUnitPrice - row.discount;
            const grossSum = netSum * (1 + row.vat / 100);
            return netSum < 0 || grossSum < 0;
        });
        if (!hasNegativeResult) {
            setSendData(prevSendData => {
                const tempArray = [...prevSendData]
                tempArray[rowIndex].quantity = updatedRows[rowIndex].quantity
                tempArray[rowIndex].unitGrossPrice = updatedRows[rowIndex].grossUnitPrice
                tempArray[rowIndex].netDiscountAmount = updatedRows[rowIndex].discount === "" ? 0 : updatedRows[rowIndex].discount
                tempArray[rowIndex].netSum = updatedRows[rowIndex].netSum
                tempArray[rowIndex].grossSum = updatedRows[rowIndex].grossSum
                tempArray[rowIndex].netUnitPrice = updatedRows[rowIndex].netUnitPrice
                return tempArray
            })
            setItems(updatedRows);
        } else {
            notify("operations.negative", { type: "error", autoHideDuration: 3000 })
        }

    };

    const handleClose = () => {
        setOpenDialog(false)
    }

    const addItem = () => {
        const newId = Date.now()
        setItems([...items, {
            id: newId,
            name: '',
            unit: '',
            units: [],
            quantity: 0,
            sourceStock: 0,
            targetStock: 0,
            vat: 0,
            netUnitPrice: 0,
            grossUnitPrice: 0,
            discount: 0,
            netSum: 0,
            grossSum: 0
        }]);
        const tempArray = [...sendData]
        tempArray.push({
            warehouseProductId: null,
            warehouseProductUnitId: null,
            unitId: null,
            quantity: 0,
            unitGrossPrice: 0,
            netDiscountAmount: 0,
            netSum: 0,
            grossSum: 0,
            vat: 0,
            netUnitPrice: 0,
        })
        setSendData(tempArray)
    }

    const deleteItem = (id, index) => {
        setItems(items.filter(item => item.id !== id))
        setSendData(prevSendData => {
            const tempArray = [...prevSendData]
            tempArray.splice(index, 1)
            return tempArray
        })
    }
    return (
        <div>
            {items.length > 0 &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate("operations.product")}</TableCell>
                                <TableCell>{translate("operations.unit")}</TableCell>
                                <TableCell>{translate("operations.quantity")}</TableCell>
                                <TableCell>{translate("operations.vat")}</TableCell>
                                {detailedData?.type.type === 'MOVE' ?
                                    <>
                                        <TableCell>{translate("operations.sourceStock")}</TableCell>
                                        <TableCell>{translate("operations.targetStock")}</TableCell>
                                    </>
                                    : detailedData?.type.type === 'FROM_SUPPLIER' ?
                                        <TableCell>{translate("operations.targetStock")}</TableCell>
                                        :
                                        <TableCell>{translate("operations.sourceStock")}</TableCell>

                                }
                                <TableCell>{translate("operations.netto")}</TableCell>
                                <TableCell>{translate("operations.brutto")}</TableCell>
                                <TableCell>{translate("operations.discount")}</TableCell>
                                <TableCell>{translate("operations.sumNetto")}</TableCell>
                                <TableCell>{translate("operations.sumBrutto")}</TableCell>
                                <TableCell>{translate("operations.delete")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Autocomplete
                                            sx={{ width: 170, marginX: -1 }}
                                            value={row.name}
                                            getOptionLabel={option => option.name || ""}
                                            renderInput={(params) => <TextField {...params}
                                                label={translate("operations.product")} />}
                                            options={products}
                                            onChange={async (e, value) => {
                                                const updatedRows = [...items];
                                                updatedRows[index].name = value
                                                let unitId = value.units.find(obj => obj.unitId !== null && obj.unitId !== undefined)
                                                if (unitId) {
                                                    unitId = unitId.unitId
                                                    try {
                                                        let units = await dataProvider.get(`warehouse-operation/unit`, { unitId: unitId })
                                                        units = units.data.map(unit => {
                                                            return { ...unit, isUnitId: true }
                                                        })
                                                        updatedRows[index].units = value.units.concat(units).filter((item, index, self) => index === self.findIndex((t) => (t.id === item.id)))
                                                    } catch (e) {
                                                        updatedRows[index].units = value.units
                                                    }
                                                } else {
                                                    updatedRows[index].units = value.units
                                                }
                                                let vat = vatGroup.find(vat => vat.id === value.vatId)
                                                vat = vat.name.split("%")[0] === 'adójegyes' ? 0 : vat.name.split("%")[0]
                                                updatedRows[index].vat = vat
                                                updatedRows[index].unit = row.units.find(item => item.id == row.name.defaultWarehouseUnitId) || row.name.units[0]
                                                updatedRows[index] = await stockChange(updatedRows[index])
                                                updatedRows[index] = await defaultPrice(updatedRows[index], index)
                                                setSendData(prevSendData => {
                                                    const tempArray = [...prevSendData]
                                                    tempArray[index].vat = vat
                                                    tempArray[index].warehouseProductId = value.id
                                                    if (updatedRows[index].unit?.isUnitId) {
                                                        tempArray[index].unitId = updatedRows[index].unit?.id
                                                        tempArray[index].warehouseProductUnitId = null
                                                    } else {
                                                        tempArray[index].unitId = null
                                                        tempArray[index].warehouseProductUnitId = updatedRows[index].unit?.id
                                                    }
                                                    return tempArray
                                                })
                                                setItems(updatedRows)

                                            }}
                                            disableClearable
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            sx={{ width: 140, marginX: -1.5 }}
                                            value={row.unit}
                                            defaultValue={row.unit}
                                            getOptionLabel={option => option.name || ""}
                                            renderInput={(params) => <TextField {...params}
                                                label={translate("operations.unit")} />}
                                            options={row.units}
                                            onChange={async (e, value) => {
                                                const updatedRows = [...items];
                                                updatedRows[index].unit = value
                                                updatedRows[index] = await stockChange(updatedRows[index])
                                                updatedRows[index] = await defaultPrice(updatedRows[index], index)
                                                setItems(updatedRows)
                                                setSendData(prevSendData => {
                                                    const tempArray = [...prevSendData]
                                                    if (value.isUnitId) {
                                                        tempArray[index].unitId = value.id
                                                        tempArray[index].warehouseProductUnitId = null
                                                    }
                                                    else {
                                                        tempArray[index].unitId = null
                                                        tempArray[index].warehouseProductUnitId = value.id
                                                    }
                                                    return tempArray
                                                })
                                            }}
                                            disableClearable
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            sx={{ marginX: -1, width: 90 }}
                                            variant={"filled"}
                                            inputProps={{ step: false }}
                                            value={row.quantity}
                                            defaultValue={row.quantity}
                                            onChange={(e) => {
                                                handleInputChange(e, index, 'quantity')
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{
                                            marginX: -1,
                                            display: 'flex',
                                            alignItems: 'end',
                                            marginTop: '4px',
                                            paddingBottom: '4px !important',
                                            height: '48px',
                                            minWidth: '30px',
                                            verticalAlign: 'bottom',
                                            backgroundColor: '#f5f5f5',
                                            padding: '8px',
                                            borderBottom: '1px solid gray'
                                        }}>{row.vat}%</Typography>
                                    </TableCell>
                                    {detailedData?.type.type === 'MOVE' ? <><TableCell>
                                        <Typography sx={{
                                            marginX: -1,
                                            display: 'flex',
                                            alignItems: 'end',
                                            marginTop: '4px',
                                            paddingBottom: '4px !important',
                                            height: '48px',
                                            minWidth: '30px',
                                            verticalAlign: 'bottom',
                                            backgroundColor: '#f5f5f5',
                                            padding: '8px',
                                            borderBottom: '1px solid gray'
                                        }}>{new bigDecimal(row.sourceStock).round(2).value}</Typography>
                                    </TableCell>
                                        <TableCell>
                                            <Typography sx={{
                                                marginX: -1,
                                                display: 'flex',
                                                alignItems: 'end',
                                                marginTop: '4px',
                                                paddingBottom: '4px !important',
                                                height: '48px',
                                                minWidth: '30px',
                                                verticalAlign: 'bottom',
                                                backgroundColor: '#f5f5f5',
                                                padding: '8px',
                                                borderBottom: '1px solid gray'
                                            }}>{new bigDecimal(row.targetStock).round(2).value}</Typography>
                                        </TableCell></>
                                        : detailedData?.type.type === 'FROM_SUPPLIER' ?
                                            <TableCell>
                                                <Typography sx={{
                                                    marginX: -1,
                                                    display: 'flex',
                                                    alignItems: 'end',
                                                    marginTop: '4px',
                                                    paddingBottom: '4px !important',
                                                    height: '48px',
                                                    minWidth: '30px',
                                                    verticalAlign: 'bottom',
                                                    backgroundColor: '#f5f5f5',
                                                    padding: '8px',
                                                    borderBottom: '1px solid gray'
                                                }}>{new bigDecimal(row.targetStock).round(2).value}</Typography>
                                            </TableCell>
                                            :
                                            <TableCell>
                                                <Typography sx={{
                                                    marginX: -1,
                                                    display: 'flex',
                                                    alignItems: 'end',
                                                    marginTop: '4px',
                                                    paddingBottom: '4px !important',
                                                    height: '48px',
                                                    minWidth: '30px',
                                                    verticalAlign: 'bottom',
                                                    backgroundColor: '#f5f5f5',
                                                    padding: '8px',
                                                    borderBottom: '1px solid gray'
                                                }}>{new bigDecimal(row.sourceStock).round(2).value}</Typography>
                                            </TableCell>
                                    }
                                    <TableCell>
                                        <TextField
                                            sx={{ width: 135, marginX: -1 }}
                                            value={row.netUnitPrice}
                                            onChange={(e) => {
                                                handleInputChange(e, index, 'netUnitPrice')
                                            }}
                                            variant={"filled"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            sx={{ width: 135, marginX: -1 }}
                                            value={row.grossUnitPrice}
                                            onChange={(e) => {
                                                handleInputChange(e, index, 'grossUnitPrice')
                                            }}
                                            variant={"filled"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            sx={{ marginX: -1, width: 90 }}
                                            value={row.discount}
                                            onChange={(e) => {
                                                handleInputChange(e, index, 'discount')
                                            }}
                                            variant={"filled"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{
                                            marginLeft: -2,
                                            display: 'flex',
                                            alignItems: 'end',
                                            marginTop: '4px',
                                            paddingBottom: '4px !important',
                                            height: '48px',
                                            minWidth: '105px',
                                            verticalAlign: 'bottom',
                                            backgroundColor: '#f5f5f5',
                                            padding: '8px',
                                            borderBottom: '1px solid gray'
                                        }}>{isNaN(row.netSum) ? 0 : new bigDecimal(row.netSum).round(2, bigDecimal.RoundingModes.HALF_UP).value}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{
                                            marginX: -2,
                                            display: 'flex',
                                            alignItems: 'end',
                                            marginTop: '4px',
                                            paddingBottom: '4px !important',
                                            height: '48px',
                                            minWidth: '105px',
                                            verticalAlign: 'bottom',
                                            backgroundColor: '#f5f5f5',
                                            padding: '8px',
                                            borderBottom: '1px solid gray'
                                        }}>{isNaN(row.grossSum) ? 0 : new bigDecimal(row.grossSum).round(2, bigDecimal.RoundingModes.HALF_UP).value}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton size={"medium"} onClick={() => {
                                            deleteItem(row.id, index)
                                        }}>
                                            <DeleteIcon fontSize={"medium"} sx={{ color: "red", marginX: -1 }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableCell colSpan={detailedData.type.type === 'MOVE' ? 9 : 8} sx={{ textAlign: "right" }}>{translate("operations.total")}</TableCell>
                            <TableCell>{sumNett}</TableCell>
                            <TableCell>{sumBrut}</TableCell>
                        </TableBody>
                    </Table>
                </TableContainer>}
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {translate("operations.dialogTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate("operations.alert")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setTrigger(!trigger)
                        setOpenDialog(false)
                    }}>
                        {translate("operations.continue")}</Button>
                    <Button onClick={handleClose} autoFocus>
                        {translate("operations.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton size={"medium"} sx={{ float: "right" }} onClick={addItem}>
                <AddCircleOutlineIcon fontSize={"medium"} sx={{ color: "#ff71bc" }} />
                <span style={{ fontSize: '11px', marginLeft: '3px' }}>{translate("operations.addProduct")}</span>
            </IconButton>
        </div>
    );

}

export default CustomTable;
