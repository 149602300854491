import {
    DateField,
    FunctionField,
    ShowController,
    SimpleShowLayout,
    TextField,
    useNotify,
    useRefresh
} from "react-admin";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {useState, useEffect} from "react";
import dataProvider from "../../../components/dataProvider";

const VoucherGeneratorShow = () => {
    const notify = useNotify()
    const [sortConfig, setSortConfig] = useState(null);
    const [record, setRecord] = useState(null);
    const [sortedRows, setSortedRows] = useState([]);
    const refresh = useRefresh()

    useEffect(() => {
        if (record?.instances) {
            let sortableItems = [...record.instances];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            setSortedRows(sortableItems);
        }
    }, [record, sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    return (
        <ShowController>
            {controllerProps => {
                setRecord(controllerProps.record);
                return (
                    <SimpleShowLayout {...controllerProps}>
                        <div>
                        </div>
                        <TextField source="id" label={"voucher.id"} />
                        <TextField source="name" label={"voucher.name"} />
                        <TextField source="voucherType" label={"voucher.voucherType"} />
                        <FunctionField render={
                            record => {
                                return(
                                    <div>
                                        {record.packageConfigurations?.map((packageConfiguration, index) => {
                                            return (
                                                <p key={index}>Csomag: {packageConfiguration.packageId === 2 ? "Standard" : packageConfiguration.packageId === 3 ? "Professional" : "Professional +"}, Kedvezmény: {packageConfiguration.discountPercent? packageConfiguration.discountPercent + '%' : packageConfiguration.fixPrice}</p>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        }
                                       label={"voucher.license"}
                        />
                        <TextField source="maxUsageCount" label={"voucher.maxUsageCount"} />
                        <TextField source="licenseCount" label={"voucher.licenseCount"} />
                        <FunctionField render={
                            record => {
                                return(
                                    <div>
                                        {record.validForCycle} X 30 nap
                                    </div>
                                )
                            }
                        } label={"voucher.validForCycle"} />
                        <DateField source="validFrom" label={"voucher.validFrom"} />
                        <DateField source="validTo" label={"voucher.validTo"} />
                        <FunctionField
                            render={record =>
                            {
                                return (
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography onClick={()=>requestSort('id')} variant="p" sx={{cursor: 'pointer'}} >ID</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography onClick={()=>requestSort('code')} variant="p" sx={{cursor: 'pointer'}} >Code</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="p">Actions</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="p">Felhasználva</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sortedRows.map((instance) => (
                                                    <TableRow key={instance.id}>
                                                        <TableCell>{instance.id}</TableCell>
                                                        <TableCell>{instance.code}</TableCell>
                                                        <TableCell>
                                                            <IconButton onClick={() => {
                                                                navigator.clipboard.writeText(instance.code)
                                                                    .then(function() {
                                                                        notify('szöveg a vágólapra másolva!', {type: 'info', autoHideDuration: 1000});
                                                                    })
                                                            }}>
                                                                <ContentCopyIcon/>
                                                            </IconButton>
                                                            <IconButton onClick={()=>{
                                                                dataProvider.delete('voucherGenerator/instance', {id: instance.id})
                                                                    .then(() => {
                                                                        notify('Sikeres Törlés', {type: 'info', autoHideDuration: 1000});
                                                                        setTimeout(() => {
                                                                            refresh()
                                                                        }, 1000)
                                                                    }
                                                                    )
                                                                    .catch(() => {
                                                                        notify('Sikertelen Törlés', {type: 'error', autoHideDuration: 1000});
                                                                    })
                                                            }}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            {instance.usageCount}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}}
                            label="voucher.voucherCode"
                        />
                    </SimpleShowLayout>
                )
            }}
        </ShowController>
    )
}

export default VoucherGeneratorShow