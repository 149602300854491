import {
    Show,
    TextField,
    SimpleShowLayout,
    Datagrid,
    ArrayField,
    BooleanField,
    SingleFieldList,
    ChipField, FunctionField, useTranslate
} from 'react-admin'
import React from "react";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tooltip from "@mui/material/Tooltip";
import RecyclingIcon from "@mui/icons-material/Recycling";

export const PriceListTemplateShow = () => {
    const translate = useTranslate()
    return(
        <Show>
        <SimpleShowLayout>
            <TextField source="name" label={'priceListTemplate.name'} />
            <ArrayField source="products" label="Termékek">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label={'products.name'} />
                    <FunctionField
                        label={"products.qtyUnit"}
                        render={record => `${record.quantity} ${record.unit}`}
                    />
                    <TextField source="price" label={'products.price'} />
                    <TextField source="categoryName" label={'products.mainCategory'} />
                    <TextField source="subcategoryName" label={'products.subCategory'} />
                    <TextField source="vatName" label={'products.vatGroup'} />
                    <FunctionField render={record => record.hasRecipe ? <Tooltip title={translate("products.recipeTooltip")} className={"tooltip"}><ReceiptLongIcon/></Tooltip> : ""} label={"products.recipe"}/>
                    <FunctionField render={record => record.depositFee.hasDepositFee === 'MULTI_WAY' || record.depositFee === 'ONE_WAY'? <Tooltip title={translate("products.refundBottle")}> <RecyclingIcon/> </Tooltip> : "" } label={"products.refundBottle"} />
                    <BooleanField source="isTakeawayAllowed" label={"products.isTakeawayAllowed"} />
                    <TextField source="takeawayVatName" label={"products.takeawayVatGroup"} />
                    <BooleanField source={"bulk"} label={'products.bulkItem'}/>
                    <ArrayField source={"tags"} label={'products.tags'} sortable={false}>
                        <SingleFieldList linkType={false}>
                            <ChipField source={"name"}/>
                        </SingleFieldList>
                    </ArrayField>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
    )
}