import {
    BooleanInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh, useTranslate
} from "react-admin";
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React, {useEffect, useRef, useState} from "react";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import dataProvider from "../../components/dataProvider";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const NtakIntegration = () => {
    const [ntakNumber, setNtakNumber] = useState('')
    const [ntakDataProvision, setNtakDataProvision] = useState(false)
    const [ntakChangeEnabled, setNtakChangeEnabled] = useState(true)
    const [ntakCertUploadDisabled, setNtakCertUploadDisabled] = useState(false)
    const [ntakCertExpired, setNtakCertExpired] = useState(false)
    const [cerFile, setCerFile] = useState()
    const [csrFile, setCsrFile] = useState(undefined)
    const inputRef = useRef(null)
    const notify = useNotify()
    const [isLoading, setLoading] = useState(false)
    const refresh = useRefresh()
    const translate = useTranslate()
    const [posSet, setPosSet] = useState(0)
// ntak regszám akkor mentődik ha csr fájlt generálunk
    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("ntakIntegrationPosSetId", posSet)
            dataProvider.get(`ntakIntegration/businessUnit`, {buId: posSet})
                .then(response => {
                    setNtakCertExpired(response.data.ntakCertExpired)
                    setNtakChangeEnabled(response.data.ntakChangeEnabled)
                    setNtakCertUploadDisabled(response.data.ntakCertUploadDisabled)
                    setNtakNumber(response.data.ntakRegNumber === null ? '' : response.data.ntakRegNumber)
                    setNtakDataProvision(response.data.ntakDataProvision)
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [posSet])

    const handleCSRGenerate = props => {
        setLoading(true)
        dataProvider.get(`ntakIntegration/cert`, {buId: posSet, ntak: ntakNumber})
            .then(response => {
                const contentType = "application/pkcs10"
                const base64Data = response.data.body
                const fileName = response.data.name
                const linkSource = `data:${contentType};base64,${base64Data}`
                setCsrFile({file:linkSource, fileName: fileName})
                setLoading(false)
            })
            .catch(reason => {
                console.log(reason)
                notify("businessUnits.csrGenerateError", {type: "error"})
                setLoading(false)
            })
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
        })
    }
    const handleFileChange = e => {
        if (!e.target.files || e.target.files[0] === undefined) {
            notify("businessUnits.fileSelectError", {type: "error"})
        }else {
            setCerFile(e.target.files[0])
        }
    }
    const handleCertificateUpload = () => {
        if (!cerFile) {
            notify("businessUnits.fileUploadError", {type: "error"})
        }else {
            getBase64(cerFile)
                .then(result => {
                    dataProvider.create(`ntakIntegration/cert`, {
                        data: {
                            id: posSet,
                            ntakRegNumber: ntakNumber,
                            fileName: cerFile.name,
                            fileBody: result
                        }
                    })
                        .then(value => {
                            notify("businessUnits.fileUploadSuccess", {type: "success"})
                        })
                        .catch(reason => {
                            console.log(reason)
                            notify("businessUnits.fileUploadError", {type: "error"})

                        })
                })
        }
    }
  return (
      <>
      <SelectCompanyWithBusinessUnitInput getResource={'ntakIntegration'} setPosSet={setPosSet} companySelectDisabled={true}/>
          <Box sx={{display: 'flex'}}>
          <TextField label={translate("businessUnits.ntakNumber")} value={ntakNumber} variant={"standard"} onChange={
              (event) => {
                  setNtakNumber(event.target.value)
              }}
         sx={{width: 300}}/>
              <Tooltip title={
                  <span style={{fontSize: "14px"}}>
                            {translate("businessUnits.ntakNumberH")}
                            </span>
              }
                       placement="right" arrow

              >

                  <IconButton>
                      <HelpIcon sx={{color: "#ff71bc"}}/>
                  </IconButton>
              </Tooltip>
            </Box>
        <FormControlLabel sx={{width: 300}} control={<Switch checked={ntakDataProvision} onChange={()=>{
            dataProvider.update(`ntakIntegration/provision`, {id: posSet, ntakDataProvision: !ntakDataProvision})
                .then(response => {
                    setNtakDataProvision(!ntakDataProvision)
                })
                .catch(reason => {
                    console.log(reason)
                })
        }} disabled={!ntakChangeEnabled} />} label={translate("businessUnits.ntakDataProvision")} />
          {!ntakChangeEnabled ?
              <Typography style={{ marginLeft: "16px", marginTop: "-10px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.ntakChangeHint")}</Typography> : null}
          <Button style={{marginBottom: "16px", color: "white", width: 400}} variant={"contained"} disabled={(ntakNumber?.length < 8 || isLoading )} onClick={handleCSRGenerate} size={"medium"}><DownloadIcon /> {translate("businessUnits.generateCSR")}</Button>
          { csrFile ?
              <>
                  <Typography style={{ marginLeft: "16px", marginTop:'15px'}} variant={"h6"}>{csrFile.fileName}</Typography>
                  <a download={csrFile.fileName} href={csrFile.file} style={{textDecoration: "none", marginLeft: "16px", marginTop:'15px'}}>
                      <Button variant={"contained"} style={{color: "white", marginBottom: "10px"}} color={"primary"} onClick={refresh}>
                          <FileDownloadIcon />   {translate('businessUnits.download')}
                      </Button>
                  </a>
              </>
              :
              null
          }
          { isLoading ?
              <>
                  <CircularProgress /> <Typography style={{ marginLeft: "16px", marginTop: '15px'}} variant={"h6"}>{translate("businessUnits.loading")}</Typography>
              </>
              :
              null
          }
          {(ntakCertExpired != null) ?
              <Typography style={{ marginLeft: "16px", marginTop: "10px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.certExpired")} {ntakCertExpired} </Typography> : null}
          <Button style={{marginBottom: "16px", color: "white", width: 400}} variant={"contained"} onClick={() => (inputRef.current?.click())} size={"medium"} disabled={ntakCertUploadDisabled}><AddIcon /> {translate("businessUnits.certAdd")}</Button>
          <input ref={inputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
          <div>{cerFile && `${cerFile.name} - ${cerFile.type}`}</div>
          {cerFile ?
              <>
                  <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} onClick={handleCertificateUpload} size={"medium"} disabled={ntakCertUploadDisabled}><UploadFileIcon /> {translate("businessUnits.certUpload")}</Button>
              </>
              :
              null
          }
          <Typography style={{ marginLeft: "16px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.certHint")}</Typography>
      </>
  );
}

export default NtakIntegration;