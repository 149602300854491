import {
    TextInput, useTranslate,
    useRecordContext, BooleanInput, SimpleForm,
    required, AutocompleteInput, regex, useDataProvider,
    useNotify, useRedirect,
} from "react-admin";
import React, { useEffect, useState } from "react";
import { PinkToolbar } from "../../components/PinkToolbar";
import HelpIcon from '@mui/icons-material/Help';
import { Box, Tooltip, IconButton } from "@mui/material";


export const BusinessUnitDetails = () => {
    const dataProvider = useDataProvider()
    const [data, setData] = useState({ loading: false, loaded: false, data: [], error: undefined });
    const record = useRecordContext()
    const translate = useTranslate()
    const [companyId, setCompanyId] = useState(record?.companyId);
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({ ...data, loading: true });
            dataProvider.get(record ? 'businessUnit/allowed-companies-for-bu' : "businessUnit/bu-create", { buId: record?.id })
                .then(value => {
                    setData({ loading: false, loaded: true, data: value.data, error: undefined });
                })
                .catch(reason => {
                    setData({ loading: false, loaded: true, data: undefined, error: reason });
                })
        }
    }, [data.loading, data.loaded]);


    useEffect(() => {
        if (record?.hasOpenWorkday) {
            setCompanyId(record.companyId);
        }
    }, [record]);

    const handleSubmit = (values) => {
        if(record) {
            if (record?.hasOpenWorkday) {
                values.companyId = companyId;
            }
            dataProvider.update("businessUnit", {id: record.id, data: values})
                .then(() => {
                    notify("businessUnits.success", {type: "success"});
                    setTimeout(() => {
                    redirect("/businessUnit");
                    window.location.reload();
                    }, 1000);
                })
                .catch(() => {
                    notify("businessUnits.error", {type: "error"});
                });
        } else {
            values.simplifiedFlag = true;
            dataProvider.create("businessUnit", {data: values})
                .then(() => {
                    notify("businessUnits.success", {type: "success"});
                    redirect("/businessUnit");
                })
                .catch(() => {
                    notify("businessUnits.error", {type: "error"});
                });
        }
     };

    return (
        <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} />} mode="onBlur" reValidateMode="onBlur"  onSubmit={handleSubmit}>
            <Box sx={{display: 'flex'}}>
            <AutocompleteInput
                source="companyId"
                choices={data.data}
                disabled={record?.hasOpenWorkday}
                optionText={((record) => { return record.name })}
                sx={{ width: '14.5em' }}
                label={"businessUnits.company"}
                validate={required()}
                onChange={(value) => {setCompanyId(value)}}
            />
            {record?.hasOpenWorkday ? <Tooltip title={translate('businessUnits.companyChangeHint')}>
                <IconButton>
                    <HelpIcon sx={{ color: "#ff71bc" }} />
                </IconButton>
            </Tooltip> : null}
            </Box>
            <TextInput source="name" label={"businessUnits.name"} inputProps={{ maxLength: 255 }} validate={required()} />
            <TextInput source="zipCode" label={"businessUnits.zipCode"} type={"number"} inputProps={{ maxLength: 10 }} validate={[regex(/^[1-9]\d{3,9}$/, "businessUnits.wrongZipCodeFormat"), required()]} />
            <TextInput source="settlement" label={"businessUnits.settlement"} inputProps={{ maxLength: 50 }} validate={required()} />
            <TextInput source="publicDomainName" label={"businessUnits.publicDomainName"} inputProps={{ maxLength: 255 }} validate={required()} />
            <TextInput source="publicDomainType" label={"businessUnits.publicDomainType"} inputProps={{ maxLength: 50 }} validate={required()} />
            <TextInput source="number" label={"businessUnits.number"} inputProps={{ maxLength: 10 }} validate={required()} />
            <BooleanInput name={'simplifiedFlag'} source={'simplifiedFlag'} label={"businessUnits.isSimplified"} defaultValue={true} disabled={!record}/>
        </SimpleForm>
    )
}