import Dialog from "@mui/material/Dialog"
import { Button, DialogTitle, IconButton } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import React, { useEffect, useRef, useState } from "react"
import { SimpleForm, useDataProvider, useNotify, useTranslate } from "react-admin"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import * as XLSX from "xlsx"
import UploadFileIcon from "@mui/icons-material/UploadFile"

export const ProductUploadDialog = (props) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const inputRef = useRef(null)
    const [excelFile, setExcelFile] = useState(null)
    const [fileName, setFileName] = useState("")
    const [units, setUnits] = useState([])
    const [vatGroups, setVatGroups] = useState([])
    const [mainCategories, setMainCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])


    useEffect(() => {
        dataProvider.get('product/unit').then(res => setUnits(res.data.content))
        dataProvider.get('product/vatGroup').then(res => setVatGroups(res.data.content))
        dataProvider.get('product/mainCategory').then(res => setMainCategories(res.data.content))
        dataProvider.get('product/subCategories').then(res => setSubCategories(res.data))
    }, [])
    const findObjectIdByName = (array, name) => {
        return array.find(item => item.name.toLowerCase() === name.toLowerCase())?.id
    }

    const findUnitIdByName = (array, name) => {
        const found = array.some(item => item.name.includes(name))
        if (!found) {
            return -1
        } else {
            return array.find(item => item.name === name)?.id
        }
    }
    const findVatId = vatGroupName => {
        if (vatGroupName === "adójegyes") {
            return findObjectIdByName(vatGroups, vatGroupName)
        }
        return findObjectIdByName(vatGroups, String((vatGroupName * 100).toFixed(0)) + '%')
    }

    const getMainAndSubcategoryId = (mainCategoryName, subCategoryName) => {
        const mainCategoryId = findObjectIdByName(mainCategories, mainCategoryName)
        const subCategoryId = subCategories.find(item => item.name.toLowerCase() === subCategoryName.toLowerCase() && item.parentCategory === mainCategoryId)?.id
        return [mainCategoryId, subCategoryId]
    }

    const getIdsFromErrorMessage = (text) => {
        const regex = /\d+/g;
        const numbers = [];
        let match
        while ((match = regex.exec(text)) !== null) {
            numbers.push(parseInt(match[0]));
        }
        if (numbers.length === 2) {
            return {
                productUnitId: numbers[0],
                productId: numbers[1]
            }
        } else {
            return null
        }
    }

    const handleFileChange = (e) => {
        let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
        let selectedFile = e.target.files[0]
        setFileName(selectedFile.name)
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            let reader = new FileReader()
            reader.readAsArrayBuffer(selectedFile)
            reader.onload = (e) => {
                setExcelFile(e.target.result)
            }
        } else {
            setExcelFile(null)
            notify("products.fileExtensionError", { type: "error" })
        }
    }

    const handleFileSubmit = () => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'array' })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            const excelData = data.filter(item => Object.keys(item).length > 1)
            const posSetId = worksheet['AA1'] ? worksheet['AA1'].v : null
            if (posSetId !== props.selectedPosSetId) {
                for (let i = 1; i < excelData.length; i++) {
                    excelData[i][10] = ''
                }
            }
            excelData.shift();
            const products = excelData.map(prop => {
                const ids = getMainAndSubcategoryId(prop[2], prop[3])
                const customCategory = getCustomCategory(prop)
                return {
                    id: prop[0],
                    name: prop[1],
                    posSetId: props.selectedPosSetId,
                    mainCategoryId: ids[0],
                    subCategoryId: ids[1],
                    customCategory: customCategory,
                    vatId: findVatId(prop[7]),
                    isTakeawayAllowed: prop[8] === "Igen",
                    takeawayVatId: findVatId(prop[9]),
                    units: [{
                        id: prop[10],
                        unit: findUnitIdByName(units, prop[11]),
                        quantity: prop[12],
                        bulk: prop[13] === "Igen",
                        depositFee:{hasDepositFee: prop[14] === "Igen" ? "ONE_WAY" : "NONE" ,depositFeeCount: prop[14] === "Igen" ? prop[15] : 1,},
                        defaultPrice: prop[16]
                    }],
                    tags: prop[17] ? prop[17].split(';').map(item => item.trim()) : null
                };
            })
            for (let i = 0; i < products.length; i++) {
                if (products[i].units.some(unit => unit.defaultPrice === '' || unit.defaultPrice === undefined)) {
                    products[i].units = [];
                }
            }
            dataProvider.create("product/saveProducts", { data: products }).then(value => {
                notify("products.saveSuccess", { type: "success" })
                closeDialog()
            })
                .catch(reason => {
                    const ids = getIdsFromErrorMessage(reason.message)
                    if (ids !== null) {
                        notify(translate("productImportError", {
                            productUnitId: ids.productUnitId,
                            productId: ids.productId
                        }), { type: "error" })
                    } else if (reason.message.includes('Wrong unit')) {
                        notify("products.wrongUnit", { type: "error" })

                    } else {
                        notify("products.saveError", { type: "error" })
                    }
                }
                )
        } else {
            closeDialog()
        }
    }

    function getCustomCategory(prop) {
        const categories = [prop[4], prop[5], prop[6]];

        if (categories.every(item => item === undefined)) {
            return null;
        }
    
        return categories
            .filter(item => item !== undefined)
            .join('/.')
    }

    const closeDialog = () => {
        setExcelFile(null)
        setFileName(null)
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <IconButton
                aria-label="close"
                onClick={() => closeDialog()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{translate("products.import")}</DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <SimpleForm toolbar={false} onSubmit={handleFileSubmit}>
                    <Button style={{ color: "white", width: "200px" }} variant={"contained"} startIcon={<AddIcon />}
                        onClick={() => (inputRef.current?.click())}>{translate("products.fileAdd")}</Button>
                    <input ref={inputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    <div style={{ marginBottom: "20px" }}>{excelFile && fileName}</div>
                    <Button startIcon={<UploadFileIcon />} style={{ color: "white", width: "200px" }} variant={"contained"}
                        disabled={!excelFile} type={'submit'}>{translate("products.fileUpload")}</Button>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
}

