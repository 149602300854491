import {Pagination, useListContext} from "react-admin";
import React, {useEffect, useState} from "react";

export const PinkPagination = (trigger) => {
    const {page, perPage, total, setPage, setFilters} = useListContext();
    const [prevState, setPrevState] = useState(0)
    const [prevTags, setPrevTags] = useState([])
    const [prevBuId, setPrevBuId] = useState(0)

    useEffect(() => {
        if((prevState !== trigger.trigger || prevTags !== trigger.tags || prevBuId !== trigger.buId) && parseInt(trigger.trigger) !== 0){
            setPage(1)
            setFilters({posSetId: trigger.trigger, tags: trigger.tags, buId: trigger.buId}, [], false)
            setPrevState(trigger.trigger)
            setPrevTags(trigger.tags)
            setPrevBuId(trigger.buId)
        }
    }, [trigger]);

    return (
        <div>
        <Pagination
            total={total}
            page={page}
            rowsPerPage={perPage}
            rowsPerPageOptions={[10, 50, 100, 500]}
        />
        </div>
    );
}