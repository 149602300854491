import {
    Button, LinearProgress,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {useTranslate, useDataProvider, useNotify, Title} from "react-admin";
import React, {useEffect, useState} from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from "moment-timezone";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {AggregateCard} from "./AggregateCard";
import {DistributionRatioCard} from "./DistributionRatioCard";
import {Top10BarCard} from "./Top10BarCard";
import {BarChartCard} from "./BarChartCard";
import cloneDeep from 'lodash/cloneDeep';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import 'dayjs/locale/hu';
import dayjs from "dayjs";
import { useLocaleState } from 'react-admin';
import {Top10ProductCard} from "./Top10ProductCard";
import RecursiveTreeView from "../../components/RecursiveTreeView";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {ThemeProvider} from "@mui/material/styles";

export const Dashboard = () => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const dateFormatFull = "YYYY.MM.DD HH:mm"
    const dateFormatHourMinute = "HH:mm"
    const currentTime = moment();
    const refreshEverything = ["AGGREGATE", "TOP10", "COUNT_BY_INTERVAL", "SUM_BY_INTERVAL"]
    const [hasActiveWorkday, setHasActiveWorkday] = useState(undefined);
    const [selectedIds, setSelectedIds] = useState([]);
    const [data, setData] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [countByInterval, setCountByInterval] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [sumByInterval, setSumByInterval] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [posSetData, setPosSetData] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [top10Bar, setTop10Bar] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [refreshTopProduct, setRefreshTopProduct] = useState(false)
    const notify = useNotify();
    const [queryParams, setQueryParams] = useState({
        type: "activeWorkday",
        rangeStart: dayjs().subtract(1, 'day').set('hour' ,6).set("minute", 0).set('second', 0),
        rangeEnd: dayjs(),
        counterInterval: 60,
        sumInterval: 60
    })
    const locale = useLocaleState()

    const aggregateData = data.data ? data.data : []
    const countByIntervalData = countByInterval.data  ? countByInterval.data : []
    const sumByIntervalData = sumByInterval.data ? sumByInterval.data : []

    useEffect(() => {
        if(selectedIds.length !== 0){
            setHasActiveWorkday(undefined)
            dataProvider.get('dashboard/hasOpenWorkday', {posSetIds: [selectedIds]}).then(value => {
                if(!value.data.hasOpenWorkday){
                    notify("dashboard.noWorkday", {type: "warning"})
                    setQueryParams({...queryParams, type: "dateRangeFilter", rangeStart:  dayjs().subtract(1, 'day').set('hour' ,6).set("minute", 0).set('second', 0)})
                    setHasActiveWorkday(false)
                }else{
                    setHasActiveWorkday(true)
                    setQueryParams({...queryParams, rangeStart: dayjs(value.data.openedAt), type: "activeWorkday"})
                }
            })
        }
    }, [selectedIds])

    const sortedTreeData = (data) => {
        return data.sort((a, b) => a.name.localeCompare(b.name)).map(item => {
            if (item.children) {
                item.children = sortedTreeData(item.children);
            }
            return item;
        });
    }

    useEffect(() => {
        if (!posSetData.loading && !posSetData.loaded) {
            setPosSetData({...posSetData, loading: true});
            dataProvider.get('dashboard')
                .then(value => {
                    const sortedData = sortedTreeData(value.data);
                    setPosSetData({loading: false, loaded: true, data: sortedData, error: undefined});
                })
                .catch(reason => {
                    setPosSetData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [posSetData.loading, posSetData.loaded]);


    const handleRefresh = (toBeRefreshed) => {
        if(toBeRefreshed.includes("AGGREGATE")) {
            setData({...data, loaded: false})
        }
        if(toBeRefreshed.includes("TOP10")) {
            setTop10Bar({...top10Bar, loaded: false})
        }
        if(toBeRefreshed.includes("COUNT_BY_INTERVAL")) {
            setCountByInterval({...countByInterval, loaded: false})
        }
        if(toBeRefreshed.includes("SUM_BY_INTERVAL")) {
            setSumByInterval({...sumByInterval, loaded: false})
        }
    }

    useEffect(() => {
        if(selectedIds.length === 0){
            setData({...data, data: []})
            setSumByInterval({...sumByInterval, data: []})
            setCountByInterval({...countByInterval, data: []})
            setTop10Bar({...top10Bar, data: []})
        }else {
            handleRefresh(refreshEverything)
        }
    }, [selectedIds])

    const handleFilter = () => {
        const rangeStart = cloneDeep(queryParams.rangeStart)
        if(queryParams.rangeEnd < queryParams.rangeStart){
            notify("dashboard.endBeforeStartError", {type: "error"})
            return
        }
        if(rangeStart.add(7, 'days').add(1, "seconds") < queryParams.rangeEnd){
            notify("dashboard.moreThanSevenDayInterval", {type: "error"})
            return
        }
        if(queryParams.rangeEnd > currentTime )
        {
            notify("dashboard.endIsTooMuch", {type: "error"})
            return
        }
        setRefreshTopProduct(true)
        handleRefresh(refreshEverything)
    }


    const handleToggle = (event, newAlignment) => {
        if(selectedIds.length !== 0) {
            if(queryParams.type !== null && newAlignment !== null) {
                setQueryParams({...queryParams, type: newAlignment});
                handleRefresh(refreshEverything);
                setRefreshTopProduct(true)
            }
        }else{
            notify("dashboard.selectCompanyOrBu", {type: "warning"})
        }
    };

    useEffect(() => {
        if (!data.loading && !data.loaded && hasActiveWorkday !== undefined && selectedIds.length !== 0) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod = queryParams.type === "dateRangeFilter" || hasActiveWorkday === false ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" || hasActiveWorkday === false ? queryParams.rangeEnd.toISOString() : null
            setData({...data, loading: true});
            dataProvider.get('dashboard/aggregates', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, posSetIds: [selectedIds]})
                .then(value => {
                    const aggregate = value.data
                    setData({loading: false, loaded: true, data: aggregate, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded, hasActiveWorkday, selectedIds]);

    useEffect(() => {
        if (!countByInterval.loading && !countByInterval.loaded && hasActiveWorkday !== undefined && selectedIds.length !== 0) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ?  queryParams.rangeEnd.toISOString() : null
            setCountByInterval({...countByInterval, loading: true})
            dataProvider.get('dashboard/countByTimeInterval', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, groupingIntervalInMinutes: queryParams.counterInterval, posSetIds: [selectedIds]})
                .then(value => {
                    const formattedData = value.data.map(it => {return {trxCount: it.trxCount, date: moment(new Date(it.intervalStart)).format(dateFormatHourMinute)}})
                    setCountByInterval({loading: false, loaded: true, data: formattedData, error: undefined});
                })
                .catch(reason => {
                    setCountByInterval({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [countByInterval.loading, countByInterval.loaded, hasActiveWorkday, selectedIds]);

    useEffect(() => {
        if (!top10Bar.loading && !top10Bar.loaded && hasActiveWorkday !== undefined && selectedIds.length !== 0) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeEnd.toISOString() : null
            setTop10Bar({...top10Bar, loading: true})
            dataProvider.get('dashboard/top-bar', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, posSetIds: [selectedIds]})
                .then(value => {
                    setTop10Bar({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setTop10Bar({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [top10Bar.loading, top10Bar.loaded, hasActiveWorkday, selectedIds]);

    useEffect(() => {
        if (!sumByInterval.loading && !sumByInterval.loaded && hasActiveWorkday !== undefined && selectedIds.length !== 0) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" || hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ?  queryParams.rangeEnd.toISOString() : null
            setSumByInterval({...sumByInterval, loading: true})
            dataProvider.get('dashboard/sumAmountByTimeInterval', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, groupingIntervalInMinutes:queryParams.sumInterval, posSetIds: [selectedIds]})
                .then(value => {
                    const formattedData = value.data.map(it => {return {total: it.totalMinusTip, tip: it.tip,  date: moment(new Date(it.intervalStart)).format(dateFormatHourMinute)}})
                    setSumByInterval({loading: false, loaded: true, data: formattedData, error: undefined});
                })
                .catch(reason => {
                    setSumByInterval({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [sumByInterval.loading, sumByInterval.loaded, hasActiveWorkday, selectedIds]);


    return (
        <div style={{display: "flex", margin: "1rem", flexDirection: "column"}}>
            <Title title="dashboard.title" />
            {(posSetData.loading || !posSetData.loaded)
                ?
                <LinearProgress/>
                :
                <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                    {Array.isArray(posSetData.data)
                        ?
                        posSetData.data.map((tree, i) =>{
                            return(<RecursiveTreeView key={i} data={tree} selected={selectedIds} setSelected={setSelectedIds} disabledList={[]} />)
                        })
                        :
                        <RecursiveTreeView data={posSetData.data} selected={selectedIds} setSelected={setSelectedIds} disabledList={[]} />
                    }
                </div>
            }
            <div style={{display: "flex", marginBottom: "3rem"}}>
                <Button disabled={queryParams.type !== "activeWorkday"} size={"large"}  onClick={e => {handleRefresh(refreshEverything) }}>
                     {translate('dashboard.refresh')}  <RefreshIcon />
                </Button>
                <ThemeProvider theme={muiDatagridTheme}>
            <ToggleButtonGroup
                color="primary"
                value={queryParams.type}
                exclusive
                onChange={handleToggle}
                style={{marginLeft: "1rem", marginRight: "1rem"}}
            >
                <ToggleButton color={"secondary"} size={"large"} disabled={!hasActiveWorkday} value="activeWorkday">{translate("dashboard.activeWorkday")}</ToggleButton>
                <ToggleButton size={"large"}
                              color={"secondary"}
                              value="dateRangeFilter">{translate("dashboard.dateRangeFilter")}</ToggleButton>
            </ToggleButtonGroup>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                <DateTimePicker
                    label={translate("dashboard.date.start")}
                    value={queryParams.rangeStart || null}
                    disabled={queryParams.type !== "dateRangeFilter"}
                    maxDate={queryParams.rangeEnd}
                    onChange={(newValue) => {
                        setQueryParams({...queryParams, rangeStart: newValue});
                    }}
                />

                <DateTimePicker
                    label={translate("dashboard.date.end")}
                    disabled={queryParams.type !== "dateRangeFilter"}
                    value={queryParams.rangeEnd || null}
                    minDate={queryParams.rangeStart}
                    maxDate={dayjs()}
                    onChange={(newValue) => {
                        setQueryParams({...queryParams, rangeEnd: newValue});
                    }}
                />
            </LocalizationProvider>
                </ThemeProvider>
                <Button disabled={queryParams.type !== "dateRangeFilter"} size={"large"} onClick={handleFilter}>
                    {translate('dashboard.filter')} <FilterAltIcon />
                </Button>
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <AggregateCard title={translate("dashboard.sumPurchase")} value={Number(aggregateData.sumPurchase || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.sumTip")} value={Number(aggregateData.sumTip || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.sumCanceled")} value={Number(aggregateData.sumCanceled || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.total")} value={Number(aggregateData.total || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.averageCartValue")} value={Number(aggregateData.averageCartValue || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.averageTip")} value={Number(aggregateData.averageTip || 0)} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.averageTipPercent")} value={Number(aggregateData.averageTipPercent || 0)} unit={translate("unit.percent")} />
                <AggregateCard title={translate("dashboard.countPurchase")} value={Number(aggregateData.countPurchase || 0)} unit={translate("unit.pieces")} />
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <DistributionRatioCard data={aggregateData} />
                {countByIntervalData ?
                    <BarChartCard data={countByIntervalData} title={translate("dashboard.trafficCount")}
                                  xAxisKey={"date"} queryParams={queryParams} setQueryParams={setQueryParams} bar1Name={"trxCount"} type={"COUNT"} refresh={handleRefresh}/>
                    :
                    null
                }

                {sumByIntervalData ?
                    <BarChartCard data={sumByIntervalData} title={translate("dashboard.trafficSum")}
                                  xAxisKey={"date"} queryParams={queryParams} setQueryParams={setQueryParams} bar1Name={"total"} bar2Name={"tip"} type={"SUM"}  refresh={handleRefresh}/>
                    :
                    null
                }
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <Top10ProductCard title={translate("dashboard.product")} queryParams={queryParams} selectedIds={selectedIds} hasActiveWorkday={hasActiveWorkday} doRefresh={refreshTopProduct} doneRefresh={setRefreshTopProduct}/>
                <Top10BarCard title={translate("dashboard.bar")} value={(top10Bar.data === undefined ? [] : top10Bar.data)} unit={translate("unit.huf")}/>
            </div>
        </div>

    );
}