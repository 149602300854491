import {Layout } from 'react-admin';
import {PinkAppBar} from "./PinkAppBar";
import {PinkMenu} from "./PinkMenu"
import { SidebarContext} from "./SidebarContext";
import React from "react";
import { useSidebarState } from 'react-admin';

export const PinkLayout = props => {
    const isSidebarOpen = useSidebarState()[0];
    return (
        <SidebarContext.Provider value={{isSidebarOpen}}>
            <Layout {...props} appBar={PinkAppBar} menu={PinkMenu} />
        </SidebarContext.Provider>
    );
};
