import GridLayout from "react-grid-layout";
import React, {useEffect, useState} from "react";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import './quickMenu.css'
import {
    AutocompleteInput,
    FormDataConsumer, useDataProvider, useTranslate, useNotify, useChoicesContext
} from "react-admin"
import {useFormContext, useWatch} from "react-hook-form";
import {uuidv4} from "../../tools/generate";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment-timezone";
import {CirclePicker} from 'react-color';
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";



export const QuickMenu = ({setEnableSave}) => {
    const [priceListData, setPriceListData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [priceListInstancesData, setPriceListInstancesData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const defaultColumnSize = 6
    const defaultRowSize = 7
    const dataProvider = useDataProvider()
    const {setValue} = useFormContext();
    const priceListId = useWatch({ name: 'priceListId' });
    const priceListInstanceId = useWatch({ name: 'priceListInstanceId' });
    const [products, setProducts] = useState( [])
    const [layout, setLayout] = useState( [])
    const [open, setOpen] = useState(false)
    const [coo, setCoo] = useState({x: 0, y: 0})
    const [isDragged, setIsDragged] = useState( false)
    const [backgroundCardColor, setBackgroundCardColor] = useState("#ffffff")
    const translate = useTranslate()
    const [COLUMN_SIZE, SetCOLUMN_SIZE] = useState(defaultColumnSize)
    const [ROW_SIZE, SetROW_SIZE] = useState(defaultRowSize)
    const ROW_HEIGHT = 90
    const TABLE_WIDTH = 1400
    const notify = useNotify()
    const format = "YYYY.MM.DD HH:mm"
    const circlePickerColors = ["#ffffff", "#f44336", "#ff71bc", "#9c27b0",  "#3f51b5", "#2196f3", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000"]
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const [selectedPriceList, setSelectedPriceList] = useState(null);
    const [simplifiedBu, setSimplifiedBu] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [dialogChoice, setDialogChoice] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const filteredDialogChoice = dialogChoice.filter(choice =>!products.some(product => product.id === choice.id))
    useEffect(() => {
        if (selectedPosSetId !== 0) {
            setPriceListData({...priceListData, loading: true});
            dataProvider.get(`quickMenu/pricelist-non-pageable`, {posSetId: selectedPosSetId, sort: "name,ASC"})
                .then(value => {
                    setPriceListData({loading: false, loaded: true, data: value.data, error: undefined});
                    if (simplifiedBu){
                        const selected = value.data.find(it => it.name === "Alapértelmezett Árlap")
                        setSelectedPriceList(selected)
                        setValue("priceListId", selected.id)
                } else {
                setSelectedPriceList(null)
                setValue("priceListId", null)
            }
                })
                .catch(reason => {
                    setPriceListData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [trigger]);

    useEffect(() => {
    setEnableSave(products.length > 0)
}, [products])
    useEffect(() => {
        if (priceListId) {
            setPriceListInstancesData({...priceListInstancesData, loading: true});
            dataProvider.get(`quickMenu/pricelist-instances`, {priceListTemplateId:`${priceListId}`, sort: "id,DESC"})
                .then(value => {
                    setPriceListInstancesData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setPriceListInstancesData({loading: false, loaded: true, data: undefined, error: reason});
                })
                dataProvider.get(`quickMenu/pricelist-products/${priceListInstanceId ? priceListInstanceId  : priceListId }`,{size: 2000})
                    .then(value => {
                        setDialogChoice(value.data.content.map(it => { return it.depositFeeType !=='NONE' ? null : it}).filter(it => it !== null))
                    })
                    .catch(reason => {
                        setDialogChoice([])
                    })
            setSelectedProduct(null)
        }
    }, [priceListId, priceListInstanceId]);

    const getQuickMenu = (queryId) => {
        dataProvider.get(`quickMenu/${queryId}`)
            .then(value => {
                const dataExtended = value.data.quickMenu.map(it => { return {...it, uuid: uuidv4()}})
                SetCOLUMN_SIZE(value.data.width)
                setValue("width", value.data.width)
                SetROW_SIZE(value.data.height)
                setValue("height", value.data.height)
                setProducts(
                    dataExtended.map(it => {
                        return {i: it.uuid, name: it.name, unit: it.unit, quantity: it.quantity, price: it.price, id: it.id, color: it.color ? it.color : "#ff71bc" }
                    })
                )
                setLayout( dataExtended.map(it => {
                    return {i: it.uuid, x: it.x, y: it.y, w: it.width, h: it.height, id: it.id.toString()}
                }))
            })
            .catch(reason => {
                setProducts([])
                setLayout([])
            })

    }

    const TooMuchProduct = () => {
        const {total} = useChoicesContext();
        return (
            total > 2000 ?
                <span style={{color: "red", }}> {translate("quickMenu.tooMuch")} </span>
                :
                ""

        );
    };
    const handleAdd = (productId, priceListId) => e => {
        if(productId && priceListId) {
            let totalArea = 0
            layout.forEach(it => {
               totalArea =  totalArea + (it.w * it.h)
            })
            if(totalArea >= COLUMN_SIZE * ROW_SIZE){
                notify("quickMenu.full", {type: "error"})
            }else {
                dataProvider.get(`quickMenu/pricelist-products/${priceListId}`, {filter: `{"id":[${productId}]}`})
                    .then(value => {
                        const newId = uuidv4()
                        setProducts([...products, {
                            i: newId,
                            name: value.data[0].product.name,
                            quantity: value.data[0].quantity,
                            unit: value.data[0].unit,
                            price: value.data[0].price,
                            id: value.data[0].id,
                            color: backgroundCardColor
                        }])
                        setLayout([...layout, {i: newId, x: coo.x, y: coo.y, w: 1, h: 1}])
                        notify("quickMenu.success", {type: "success"})
                    })
                    .catch(reason => {
                        console.log(reason)
                        notify("quickMenu.error", {type: "error"})
                    })
            }
            setOpen(false)
            setValue("productId", null)
            setCoo({x: 0, y: 0})
            setBackgroundCardColor("#ffffff")
        }
    }

    useEffect(() => {
        if(priceListId) {
            const queryId = priceListInstanceId ? priceListInstanceId : priceListId
            getQuickMenu(queryId)
        }
    }, [priceListInstanceId]);

    useEffect(() => {
        if(priceListId) {
            setValue("priceListInstanceId", null)
            SetCOLUMN_SIZE(defaultColumnSize)
            SetROW_SIZE(defaultRowSize)
            getQuickMenu(priceListId)
        }
    }, [priceListId]);

    const handleDialogClose = () => {
        setOpen(false)
        setCoo({x: 0, y: 0})
        setBackgroundCardColor("#ffffff")
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }

    const layoutHandle = (lay) => {
        //handles if the newly inserted item has collisions
        lay.map(it => {
            if(it.y >= ROW_SIZE){
                it.y = 0
                it.x = it.x + 1
            }
            return it
        })
        setValue('layouts', lay)
        setValue("products", products)
        setLayout(lay)
    }

    const handleRemove = (removedId) => e => {
        e.stopPropagation();
            const newLayout = layout.filter(item => item.i !== removedId)
            const newProducts = products.filter(item => item.i !== removedId)
            setLayout(newLayout)
            setProducts(newProducts)
    }

    const getCoordinate = e =>
    {
        const elem = document.getElementById('clickCoordinate')
        const rect = elem.getBoundingClientRect()
        const x = Math.floor((e.clientX - rect.x)/(rect.width/COLUMN_SIZE))
        const y = Math.floor((e.clientY - rect.y)/(rect.height/ROW_SIZE))
        setCoo( {x, y})
        const found = (layout.find(obj => {return obj.x <= x && (obj.x+obj.w-1) >= x && obj.y <= y && (obj.y+obj.h-1) >= y }))
        //TODO "Ezt felül kell vizsgálni, mert nem jó megoldás a timeout, kiszámítathetatlan hogy eltérő eszközökön mennyi idő alatt fut le" - Dani
        setTimeout(() => {
            if(priceListId !== undefined && !isDragged && found === undefined) {handleDialogOpen()}
        }, 100)
    }

    const handleDragResize = () =>{
        setIsDragged(true)
        setTimeout(() => setIsDragged(false), 100)
    }

    const doResize = (event, size) => {
        if(event.target.value !== "") {
            const value = event.target.value.replace(/\D/g, "").startsWith('0') ?  event.target.value.replace(/\D/g, "").replace("0", "") : event.target.value.replace(/\D/g, "");
            if (value > 0 && value < 20) {
                if(size === "width"){
                    SetCOLUMN_SIZE(Number(value))
                    setValue("width", Number(value))
                }else {
                    SetROW_SIZE(Number(value))
                    setValue("height", Number(value))
                }
                let newLayout = layout
                let newProducts = products
                layout.forEach(it => {
                    const sum = size === "width" ? it.x + it.w : it.y + it.h
                    if (sum > value) {
                        newLayout = newLayout.filter(item => item.i !== it.i)
                        newProducts = newProducts.filter(item => item.i !== it.i)
                    }
                setLayout(newLayout)
                setProducts(newProducts)
                })
            }
        }else{
            if(size === "width") {
                SetCOLUMN_SIZE(0)
            }else {
                SetROW_SIZE(0)
            }
        }
    }
    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const getLightness = (color) => {
        const r = parseInt(color.slice(1, 3), 16),
            g = parseInt(color.slice(3, 5), 16),
            b = parseInt(color.slice(5, 7), 16);
        //RGB to HSL képlet L részének egyszerűsítése alapján
        return 0.196*(Math.max(r,g,b)+Math.min(r,g,b))
    }

    return (
        <>
            <div style={{ width: '400px'}}>
                <SelectCompanyWithBusinessUnitInput getResource={"quickMenu"} setPosSet={setSelectedPosSetId} companySelectDisabled={true} setSimplifiedBu={setSimplifiedBu} setTrigger={setTrigger}/>

                <Autocomplete
                    sx={{width: "100%"}}
                    value={selectedPriceList || ""}
                    getOptionLabel={option => option.name || ""}
                    renderInput={(params) => <TextField {...params} label={translate("priceListTemplate.template")}
                    variant={"standard"}/>}
                    onChange={
                        (event, value) => {
                            if (value===null) {
                                setProducts([])
                            }
                            setSelectedPriceList(value);
                            setValue("priceListId", value ? value.id : null);
                        }
                    }
                    options={priceListData.data}
                />

            {priceListId ?
                <>
                    <AutocompleteInput
                        source="priceListInstanceId"
                        choices={priceListInstancesData.data}
                        optionText={((record)=> {
                            return  moment(new Date(record.createdAt)).format(format) + ' (' + record.id + ')'
                        })}
                        fullWidth={true}
                        label={"priceListTemplate.nameShort"}
                        disabled={simplifiedBu}
                        variant={"standard"}
                    />

                    <TextField source={"priceListInstanceWidth"}  label={translate("quickMenu.width")} value={COLUMN_SIZE === 0 ? "" : COLUMN_SIZE} onChange={e => doResize(e, "width")} sx={{width: 100}} />

                    <TextField source={"priceListInstanceHeight"} label={translate("quickMenu.height")} value={ROW_SIZE === 0 ? "" : ROW_SIZE} onChange={e => doResize(e, "height")} sx={{width: 100, marginLeft: 5 }} />

                    <Tooltip sx={{margin: '10px'}} title={
                        <span style={{fontSize: "14px"}}>
                            {translate("priceListTemplate.tooltip1")}
                            <br/>
                            {translate("priceListTemplate.tooltip2")}
                            <br/>
                            {translate("priceListTemplate.tooltip3")}
                            </span>
                    }
                             placement="right" arrow
                    >

                        <IconButton>
                            <HelpIcon sx={{color: "#ff71bc"}}/>
                        </IconButton>
                    </Tooltip>
                </>
                :
                null
            }

                <FormDataConsumer>
                    {({formData}) => (
                        <>
                            <Dialog open={open} onClose={handleDialogClose} >
                                <DialogTitle>{translate("quickMenu.add")}</DialogTitle>
                                <DialogContent>
                                    <Autocomplete
                                        sx={{width: "100%"}}
                                        value={selectedProduct}
                                        getOptionLabel={option => `${option.product?.name} - ${option.quantity} ${option.unit} - ${option.price} Ft` || ""}
                                        renderInput={(params) => <TextField {...params} label={translate("products.name")}
                                                                           variant={"standard"}/>}
                                        onChange={
                                            (event, value) => {
                                                setValue("productId", value ? value.id : null);
                                                setSelectedProduct(value);
                                            }
                                        }
                                        disableClearable={false}
                                        options={filteredDialogChoice}
                                    />
                                    {formData.productId ? <>
                                        <CirclePicker color={backgroundCardColor}
                                                      colors={circlePickerColors}
                                                      onChangeComplete={(color) => setBackgroundCardColor(color.hex)}
                                                      className={backgroundCardColor === '#ffffff' ? 'white-selected' : ''}
                                        />
                                    </> : null}
                                    <Button id={"addFormButton"} style={{color: "white", marginTop: "20px"}} variant={"contained"} disabled={!formData.priceListId || !formData.productId}
                                            onClick={handleAdd(formData.productId,formData.priceListInstanceId ? formData.priceListInstanceId  : formData.priceListId)}
                                            size={"medium"}><AddIcon /> {translate("quickMenu.addShort")}
                                    </Button>
                                </DialogContent>
                            </Dialog>
                            {formData.priceListId ?
                                <>
                                <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} disabled={!formData.priceListId} onClick={handleDialogOpen} size={"medium"}><AddIcon /> {translate("quickMenu.add")}</Button>

                                <div style={{width: '1000px', padding: '8px'}}>
                                    <Typography variant={'h6'}>{translate("quickMenu.addText")}</Typography>
                                </div>
                                </>
                                :
                                <div style={{width: '1000px', padding: '8px'}}>
                                    <Typography variant={'h6'}>{translate("quickMenu.choosePriceList")}</Typography>
                                </div>

                            }
                        </>
                    )}
                </FormDataConsumer>



                </div>
                <div id="clickCoordinate" onClick={e => getCoordinate(e)}>
                    <div
                    style={{ width: '1405px',
                    height: (ROW_HEIGHT*ROW_SIZE+((ROW_SIZE-1)*5))+'px',
                    backgroundImage: "repeating-linear-gradient(#ccc 0%, #ccc 1px, transparent 1px, transparent 100%),repeating-linear-gradient(90deg, #ccc 0%, #ccc 1px, transparent 1px, transparent 100%)",
                    backgroundSize: ((TABLE_WIDTH+3)/COLUMN_SIZE)+"px "+(ROW_HEIGHT+3.9)+"px"
                    }}>
                        {
                            //ROW_HEIGHT+3.9 azért van használva, mert különben rosszul jelenik meg a háttér
                        }
                    <GridLayout
                        onLayoutChange={layoutHandle}
                        layout={layout}
                        className="layout"
                        cols={COLUMN_SIZE}
                        maxRows={ROW_SIZE}
                        rowHeight={ROW_HEIGHT}
                        width={TABLE_WIDTH}
                        containerPadding={[0, 0]}
                        margin={[4, 4]}
                        compactType={null}
                        preventCollision={true}
                        draggableCancel={".notDraggable"}
                        onDragStop={() => handleDragResize()}
                        onResizeStop={() => handleDragResize()}
                    >

                    {products.map((product, index) => (
                        <div style={{backgroundColor: product.color, color: (getLightness(product.color) > 50) ? "black" : "white", display: "flex", flexDirection: "column", marginTop: "2px", marginLeft: "2px"}}
                              key={product.i}
                        >
                            <div style={{textAlign: "right", marginBottom: "0px"}}>< ClearIcon className={"notDraggable"} onClick={handleRemove(product.i)} fontSize={"small"} style={{marginBottom:"0px"}}/></div>
                            {(product.name.length < 25) ? <div>
                                <Typography onClick={e => e.preventDefault()} style={{textAlign: "center"}}>{product.name}</Typography>
                                <Typography fontSize={"small"} style={{textAlign: "center"}}>{product.quantity + " " + product.unit}</Typography>
                                <Typography fontSize={"small"} style={{textAlign: "center"}}>{product.price} Ft</Typography>
                            </div> : <div>
                                <Typography onClick={e => e.preventDefault()} fontSize={"0.95rem"}
                                            sx={{display: '-webkit-box', overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', textAlign: "center"}}>
                                    {product.name}
                                </Typography>
                                <Typography fontSize={"small"} style={{textAlign: "center"}}>{product.quantity + " " + product.unit + ", " + product.price} Ft</Typography>
                            </div>}
                        </div>

                        )
                    )
                    }
                    </GridLayout>
                    </div>
                </div>
        </>
    );
}